<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <div
          class="max-sm:flex fold:flex justify-between items-center mt-[12%] sm:mt-0"
        >
          <h2
            class="sm:text-[30px] sm:font-bold text-[20px] font-regular max-sm:text-[#444] max-sm:dark:text-[#e5e5e5] fold:text-[#444] fold:dark:text-[#e5e5e5] fold:text-[18px]"
          >
            {{ $t('trade.modal.tp_sl')
            }}<span class="sm:hidden text-[#a0a0a0]">(Stop-Limit)</span>
          </h2>
          <button class="sm:hidden shrink-0" @click="setModalControl">
            <img
              src="../../assets/image/icon_close.png"
              alt=""
              class="w-[35px] dark:hidden"
            />
            <img
              src="../../assets/image/icon_close_d.png"
              alt=""
              class="w-[35px] mt-[-35px] dark:mt-0 opacity-0 dark:opacity-100"
            />
          </button>
        </div>
        <div
          class="text-left sm:text-[20px] mt-[30px] text-[15px] sm:border-b-0 border-b border-[#c9c9c9] sm:pb-0 pb-4 dark:border-[#606060]"
        >
          <div class="flex">
            <div class="max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]">
              {{ $t('deal.entry')
              }}<span class="max-sm:hidden fold:hidden">:</span>
            </div>
            <div
              class="ml-auto max-sm:font-semibold fold:font-semibold max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
            >
              {{ average }}
              <span
                class="max-sm:text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:text-[#a0a0a0] fold:dark:text-[#9f9f9f]"
                >USDT</span
              >
            </div>
          </div>
          <div class="flex">
            <div class="max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]">
              {{ $t('deal.fillprice')
              }}<span class="max-sm:hidden fold:hidden">:</span>
            </div>
            <div
              class="ml-auto max-sm:font-semibold fold:font-semibold max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
            >
              {{ stopLimitData.recent }}
              <span
                class="max-sm:text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:text-[#a0a0a0] fold:dark:text-[#9f9f9f]"
                >USDT</span
              >
            </div>
          </div>
        </div>

        <div
          class="flex border border-[#c9c9c9] justify-between sm:leading-[50px] py-1 sm:mt-[10px] px-[10px] sm:py-0 dark:border-[#606060] mt-5 max-sm:text-[13px] max-sm:dark:bg-[#1c1d22] fold:text-[13px] fold:dark:bg-[#1c1d22]"
        >
          <div
            class="max-sm:text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:text-[#a0a0a0] fold:dark:text-[#9f9f9f]"
          >
            {{ $t('trade.modal.size2') }}
          </div>
          <input
            type="number"
            class="w-[55%] outline-none text-right dark:bg-[#1c1d22] dark:text-[#fff] dark:border-[#606060] sm:font-regular font-bold"
            :placeholder="setDecimal(this.stopLimitData.quantity, 6)"
            v-model="input.quantity"
          />
          <div
            class="sm:text-[#c9c9c9] sm:text-[18px] text-[13px] sm:font-regular font-bold text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
          >
            {{ stopLimitData.symbol.toUpperCase() }}
          </div>
        </div>
        <div
          class="flex border border-[#c9c9c9] justify-between py-1 sm:py-0 sm:leading-[50px] mt-[10px] px-[10px] dark:border-[#606060] max-sm:text-[13px] fold:text-[13px]"
        >
          <div
            class="max-sm:text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:text-[#a0a0a0] fold:dark:text-[#9f9f9f]"
          >
            {{ $t('trade.modal.profit') }}
          </div>
          <input
            type="number"
            class="w-[55%] outline-none text-right dark:bg-[#1c1d22] dark:text-[#fff] dark:border-[#606060] sm:font-regular font-bold"
            :placeholder="this.stopLimitData.recent"
            v-model="input.limitPrice"
          />
          <div
            class="sm:text-[#c9c9c9] sm:text-[18px] text-[13px] sm:font-regular font-bold text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
          >
            USDT
          </div>
        </div>
        <div
          class="flex sm:text-[18px] sm:px-[10px] text-[15px] mt-2 sm:mt-0 sm:border-b-0 border-b pb-2 sm:pb-0 border-[#c9c9c9] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5] dark:border-[#606060]"
        >
          <div>
            {{ $t('trade.modal.pnl')
            }}<span class="max-sm:hidden fold:hidden">:</span>
          </div>
          <div
            class="ml-auto font-semibold sm:font-regular"
            :class="[
              profit < 0
                ? 'text-[#fc0d1b]'
                : profit == 0
                ? 'max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]'
                : 'text-[#005cb9] dark:text-[#24ae64]'
            ]"
          >
            {{ profit > 0 ? '+' : '' }} {{ profit }}
            <span
              class="max-sm:text-[#c9c9c9] max-sm:dark:text-[#606060] fold:text-[#c9c9c9] fold:dark:text-[#606060]"
              >USDT</span
            >
          </div>
        </div>
        <div
          class="flex border border-[#c9c9c9] justify-between py-1 sm:py-0 max-sm:text-[13px] fold:text-[13px] sm:leading-[50px] mt-[20px] px-[10px] dark:border-[#606060]"
        >
          <div
            class="max-sm:text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:text-[#a0a0a0] fold:dark:text-[#9f9f9f]"
          >
            {{ $t('trade.modal.loss') }}
          </div>
          <input
            type="number"
            class="w-[55%] outline-none text-right dark:bg-[#1c1d22] sm:font-regular font-bold dark:text-[#fff]"
            v-model="input.stopPrice"
            :placeholder="this.stopLimitData.recent"
          />
          <div
            class="sm:text-[#c9c9c9] sm:text-[18px] text-[13px] text-[#a0a0a0] sm:font-regular font-bold max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
          >
            USDT
          </div>
        </div>
        <div
          class="flex sm:text-[18px] sm:px-[10px] text-[15px] mt-2 sm:my-0 sm:border-b-0 border-b pb-2 sm:pb-0 max-sm:border-[#c9c9c9] max-sm:dark:border-[#606060] fold:border-[#c9c9c9] fold:dark:border-[#606060] mb-10"
        >
          <div>
            {{ $t('trade.modal.pnl')
            }}<span class="max-sm:hidden fold:hidden"> :</span>
          </div>
          <div
            class="ml-auto font-semibold sm:font-regular"
            :class="[
              loss < 0
                ? 'text-[#fc0d1b]'
                : loss == 0
                ? ''
                : 'text-[#005cb9] dark:text-[#24ae64]'
            ]"
          >
            {{ loss > 0 ? '+' : '' }} {{ loss }}
            <span class="max-sm:text-[#c9c9c9] fold:text-[#c9c9c9]">USDT</span>
          </div>
        </div>
      </div>
      <!-- <div class="flex text-[18px] mt-[40px] pl-[10px]">
        <div>{{ $t('trade.modal.basis') }} :</div>
        <select name="" id="" class="border border-[#c9c9c9] pr-[40px] ml-auto dark:bg-[#1c1d22] dark:border-[#606060] text-[16px] max-sm:text-[13px] max-sm:py-2">
          <option value="">{{ $t('common.invest_marketprice') }}</option>
          <option value="">{{ $t('common.invest_limits') }}</option>
          <option value="">{{ $t('common.invest_conditional') }}</option>
        </select>
      </div> -->
      <div :class="tStyle.confirm">
        <button
          class="basis-1/2 sm:rounded py-1 sm:py-0 bg-[#e8eff7] sm:dark:bg-[#fff8e9] dark:text-[#333] dark:bg-[#fff]"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 sm:rounded py-1 sm:py-0 bg-[#cfe4fe] sm:dark:bg-[#ffb500] dark:text-[#333] ml-[10px] dark:bg-[#fdd26a]"
          @click="orderSubmit"
        >
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, apiLanguage } from '@/store/store';
import { stopLimit, orderConfirm } from '@/store/positionStore';
import create from 'vue-zustand';
import axios from 'axios';
import AesEncrypt from '@/components/Security/AesEncrypt';
import AesDecrypt from '@/components/Security/AesDecrypt';

export default {
  name: 'StopLimit',
  data() {
    return {
      input: {
        quantity: '',
        limitPrice: '',
        stopPrice: ''
      },
      failText: '',
      tStyle: {
        inner:
          'bg-white w-[90%] max-sm:flex fold:flex flex-col sm:w-[500px] sm:h-[700px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] px-6 fold:px-4 sm:py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'sm:text-center sm:mt-[50px] text-left',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        confirm:
          'w-full sm:h-[60px] flex sm:mt-[70px] max-sm:text-[15px] fold:text-[15px] mt-auto pb-6 sm:pb-0',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:top-[11%] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setModalName, setAlertData, setAlertOpen } =
      useModalControl();
    const useStopLimit = create(stopLimit);
    const { stopLimitData } = useStopLimit();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useOrderConfirm = create(orderConfirm);
    const { setApiCall } = useOrderConfirm();

    return {
      setModalControl,
      stopLimitData,
      setModalName,
      setAlertData,
      setAlertOpen,
      apiLangCode,
      setApiCall
    };
  },
  methods: {
    lossOrder() {
      const localToken = 'Bearer ' + localStorage.getItem('fubit_token');
      const localRtoken = 'Bearer ' + localStorage.getItem('fubit_rtoken');
      const data = {
        type: 'close',
        symbol: this.stopLimitData.symbol,
        order_type: 'limit',
        dest_ls: this.stopLimitData.dest,
        margin_ci: this.stopLimitData.margin,
        price: this.input.stopPrice,
        percent_yn: 'N',
        quantity: this.input.quantity,
        leverage: this.stopLimitData.leverage
      };
      const encrypted = AesEncrypt(data);
      const reqData = JSON.stringify({
        data: encrypted
      });
      const config = {
        method: 'post',
        url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/send_order`,
        headers: {
          language: this.apiLangCode[this.$i18n.locale],
          apikey: process.env.VUE_APP_MAIN_API_KEY,
          'Content-Type': 'application/json',
          Authorization: localToken,
          Validation: localRtoken
        },
        data: reqData
      };
      //호출
      axios(config).then((res) => {
        const decrypted = AesDecrypt(res.data.data.toString());
        const resData = JSON.parse(decrypted);
        if (resData.API_CODE.status == 'L402') {
          this.setAlertData({
            title: '',
            desc: this.$t('login.msg15')
          });
          this.setAlertOpen();
          localStorage.removeItem('fubit_token');
          localStorage.removeItem('fubit_rtoken');
          this.setLogout();
        } else if (resData.API_CODE.status == '0000') {
          if (resData.AUTH !== undefined) {
            localStorage.setItem('fubit_token', resData.AUTH.token);
            localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
          }
          // this.setApiCall()
        }
      });
    },
    profitOrder() {
      const localToken = 'Bearer ' + localStorage.getItem('fubit_token');
      const localRtoken = 'Bearer ' + localStorage.getItem('fubit_rtoken');
      const data = {
        type: 'close',
        symbol: this.stopLimitData.symbol,
        order_type: 'stop',
        dest_ls: this.stopLimitData.dest,
        margin_ci: this.stopLimitData.margin,
        price: this.input.limitPrice,
        percent_yn: 'N',
        quantity: this.input.quantity,
        leverage: this.stopLimitData.leverage
      };
      const encrypted = AesEncrypt(data);
      const reqData = JSON.stringify({
        data: encrypted
      });
      const config = {
        method: 'post',
        url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/send_order`,
        headers: {
          language: this.apiLangCode[this.$i18n.locale],
          apikey: process.env.VUE_APP_MAIN_API_KEY,
          'Content-Type': 'application/json',
          Authorization: localToken,
          Validation: localRtoken
        },
        data: reqData
      };
      //호출
      axios(config).then((res) => {
        const decrypted = AesDecrypt(res.data.data.toString());
        const resData = JSON.parse(decrypted);
        if (resData.API_CODE.status == 'L402') {
          this.setAlertData({
            title: '',
            desc: this.$t('login.msg15')
          });
          this.setAlertOpen();
          localStorage.removeItem('fubit_token');
          localStorage.removeItem('fubit_rtoken');
          this.setLogout();
        } else if (resData.API_CODE.status == '0000') {
          if (resData.AUTH !== undefined) {
            localStorage.setItem('fubit_token', resData.AUTH.token);
            localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
          }
          // this.setApiCall()
        }
      });
    },
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    },
    orderSubmit() {
      if (localStorage.getItem('fubit_token') == undefined) {
        this.setAlertData({
          title: this.$t('alert.positionOrder.title'),
          desc: this.$t('alert.positionOrder.desc')
        });
        this.setAlertOpen();
      } else {
        if (this.stopLimitData.dest === 'long') {
          if (this.input.quantity == '' || this.input.quantity == '0') {
            this.setAlertData({
              title: '',
              desc: this.$t('deal.msg3')
            });
            this.setAlertOpen();
          } else if (
            this.input.limitPrice == '' ||
            this.input.limitPrice == '0'
          ) {
            if (this.input.stopPrice == '' || this.input.stopPrice == '0') {
              this.setAlertData({
                title: '',
                desc: this.$t('deal.msg9')
              });
              this.setAlertOpen();
            } else {
              if (this.input.stopPrice > this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg11')
                });
                this.setAlertOpen();
              } else {
                this.lossOrder();
                this.setModalControl();
              }
            }
          } else {
            if (this.input.stopPrice == '0' || this.input.stopPrice == '') {
              if (this.input.limitPrice < this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg10')
                });
                this.setAlertOpen();
              } else {
                this.profitOrder();
                this.setModalControl();
              }
            } else {
              if (this.input.limitPrice < this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg10')
                });
                this.setAlertOpen();
              } else if (this.input.stopPrice > this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg11')
                });
                this.setAlertOpen();
              } else {
                this.profitOrder();
                this.lossOrder();
                this.setModalControl();
              }
            }
          }
        } else {
          if (this.input.quantity == '' || this.input.quantity == '0') {
            this.setAlertData({
              title: '',
              desc: this.$t('deal.msg3')
            });
            this.setAlertOpen();
          } else if (
            this.input.limitPrice == '' ||
            this.input.limitPrice == '0'
          ) {
            if (this.input.stopPrice == '' || this.input.stopPrice == '0') {
              this.setAlertData({
                title: '',
                desc: this.$t('deal.msg9')
              });
              this.setAlertOpen();
            } else {
              if (this.input.stopPrice < this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg13')
                });
                this.setAlertOpen();
              } else {
                this.lossOrder();
                this.setModalControl();
              }
            }
          } else {
            if (this.input.stopPrice == '0' || this.input.stopPrice == '') {
              if (this.input.limitPrice > this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg12')
                });
                this.setAlertOpen();
              } else {
                this.profitOrder();
                this.setModalControl();
              }
            } else {
              if (this.input.limitPrice > this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg12')
                });
                this.setAlertOpen();
              } else if (this.input.stopPrice < this.stopLimitData.recent) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg13')
                });
                this.setAlertOpen();
              } else {
                this.profitOrder();
                this.lossOrder();
                this.setModalControl();
              }
            }
          }
        }
      }
    },
    // 심볼 별 자리수
    digits(a) {
      if (a == 'btc') {
        return 2;
      } else if (a == 'eth') {
        return 2;
      } else if (a == 'xrp') {
        return 4;
      } else if (a == 'matic') {
        return 5;
      } else if (a == 'doge') {
        return 6;
      } else if (a == 'arb') {
        return 6;
      } else if (a == 'sei') {
        return 6;
      } else if (a == 'sol') {
        return 6;
      } else if (a == 'stx') {
        return 6;
      } else if (a == 'avax') {
        return 6;
      } else if (a == 'gas') {
        return 6;
      } else if (a == 'axs') {
        return 6;
      }
    }
  },
  computed: {
    average() {
      return this.setDecimal(
        this.stopLimitData.average,
        this.digits(this.stopLimitData.symbol)
      );
    },
    forcePrice() {
      return this.setDecimal(this.stopLimitData.force, 2);
    },
    profit() {
      const a = this.input.limitPrice - this.stopLimitData.recent;
      const b = a * this.input.quantity;
      const c = this.setDecimal(b, 2);
      if (this.stopLimitData.dest === 'long') {
        return c;
      } else {
        return -c;
      }
    },
    loss() {
      const a = this.input.stopPrice - this.stopLimitData.recent;
      const b = a * this.input.quantity;
      const c = this.setDecimal(b, 2);
      if (this.stopLimitData.dest === 'long') {
        return c;
      } else {
        return -c;
      }
    }
  },
  watch: {
    // input: {
    //   handler(a , b) {
    //     // let regexp = /^\d*.?\d{0,6}$/;
    //     // if(!regexp.test(a.quantity)){
    //     //   a.quantity = b.quantity
    //     // }
    //     if (a.quantity > this.stopLimitData.quantity) {
    //       this.input.quantity = this.stopLimitData.quantity;
    //     }
    //   },
    //   deep: true
    // }
    'input.quantity'(a, b) {
      let regexp = /^\d*.?\d{0,6}$/;
      if (!regexp.test(a)) {
        this.input.quantity = b;
      }
      if (a > this.stopLimitData.quantity) {
        this.input.quantity = this.setDecimal(this.stopLimitData.quantity, 6);
      }
    }
  },
  mounted() {}
};
</script>
<style lang=""></style>
