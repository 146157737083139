<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 px-6 sm:px-0 bg-[#f2f4f5] bg-opacity-80"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <div class="justify-between max-sm:flex fold:flex">
          <h2 class="sm:text-[30px] text-[20px] font-bold">
            <span class="text-[#C23E3E] dark:text-[#00CC88]">{{
              $t('deal.openlong')
            }}</span>
            {{ $t('deal.leverage2') }}
          </h2>
          <button class="sm:hidden" @click="setModalControl">
            <img
              src="../../assets/image/icon_close.png"
              alt=""
              class="w-[35px] dark:hidden"
            />
            <img
              src="../../assets/image/icon_close_d.png"
              alt=""
              class="w-[35px] hidden dark:inline-block"
            />
          </button>
        </div>

        <div
          class="w-full flex items-center justify-between border border-[#c9c9c9] py-2 mt-[40px] dark:border-[#606060] dark:text-[#fff]"
        >
          <div
            class="w-[24px] h-[24px] ml-4 cursor-pointer"
            @click="minusCount"
          >
            <img class="dark:hidden" src="@/assets/image/w-.png" alt="" />
            <img class="hidden dark:block" src="@/assets/image/d-.png" alt="" />
          </div>
          <div>{{ longValue }}x</div>
          <div class="w-[24px] h-[24px] mr-4 cursor-pointer" @click="plusCount">
            <img class="dark:hidden" src="@/assets/image/w+.png" alt="" />
            <img class="hidden dark:block" src="@/assets/image/d+.png" alt="" />
          </div>
        </div>
        <div class="mt-[40px]">
          <VueSlider
            :processStyle="processStyle"
            v-model="longValue"
            :dotSize="15"
            :min="1"
            :max="maxValue"
            tooltip="always"
            :tooltip-formatter="'{value}x'"
            :tooltipStyle="tooltipStyle"
            :dotOptions="dotOptions"
            :stepActiveStyle="stepActiveStyle"
            :stepStyle="stepStyle"
            :marks="marks"
            v-if="isDark === false"
          />
          <VueSlider
            :processStyle="darkProcessStyle"
            v-model="longValue"
            :dotSize="15"
            :min="1"
            :max="maxValue"
            tooltip="always"
            :tooltip-formatter="'{value}x'"
            :tooltipStyle="darkToolTipStyle"
            :dotOptions="darkDotOptions"
            :stepStyle="stepStyle"
            :stepActiveStyle="darkStepActiveStyle"
            :marks="marks"
            v-else
          />
        </div>
      </div>
      <div class="mt-4 text-[16px]">
        {{ $t('deal.msg26') }} : {{ maxPositionPrice }} USDT
      </div>
      <div :class="tStyle.confirm">
        <button
          class="basis-1/2 sm:rounded-[3px] sm:py-0 py-1 sm:bg-[#e8eff7] sm:dark:bg-[#fff8e9] dark:text-[#333] sm:border-none border border-[#cfe4fe] dark:bg-[#fff] dark:border-[#fff]"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 sm:rounded-[3px] sm:py-0 py-1 bg-[#cfe4fe] sm:dark:bg-[#ffb500] dark:text-[#333] ml-[10px] dark:bg-[#fdd26a]"
          @click="leverageSett"
        >
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, darkMode, apiLanguage } from '@/store/store';
import { leverage, aggSocketData, orderConfirm } from '@/store/positionStore';
import create from 'vue-zustand';
import VueSlider from 'vue-3-slider-component';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';

export default {
  name: 'ShortbuyLeverage',
  data() {
    return {
      leverageList:[],
      recentPrice: '',
      //coinList: ['btc', 'eth', 'xrp', 'matic', 'doge','arb','sol','stx','sei','gas','avax','axs'],
      coinList: ['btc', 'eth', 'xrp', 'doge','arb','sol','stx','sei','gas','avax','axs'],
      selectSymbol: 'btc',
      isDark: null,
      stepStyle: {
        display: 'block',
        width: '12px',
        borderRadius: '50%',
        height: '12px',
        backgroundColor: '#cccccc',
        marginTop: '-4px',
        marginLeft: '-4px'
      },
      stepActiveStyle: {
        display: 'block',
        width: '14px',
        borderRadius: '50%',
        height: '14px',
        backgroundColor: '#C23E3E',
        marginTop: '-5px',
        marginLeft: '-5px'
      },
      darkStepActiveStyle: {
        display: 'block',
        width: '14px',
        borderRadius: '50%',
        height: '14px',
        backgroundColor: '#00CC88',
        marginTop: '-5px',
        marginLeft: '-5px'
      },
      processStyle: {
        backgroundColor: '#C23E3E'
      },
      darkProcessStyle: {
        backgroundColor: '#00CC88'
      },
      dotOptions: {
        style: { backgroundColor: '#C23E3E' }
      },
      darkDotOptions: {
        style: { backgroundColor: '#00CC88' }
      },
      tooltipStyle: {
        color: '#ffffff',
        backgroundColor: '#C23E3E',
        height: '18px',
        lineHeight: '15px',
        borderColor: '#C23E3E'
      },
      darkToolTipStyle: {
        color: '#ffffff',
        backgroundColor: '#00CC88',
        height: '18px',
        lineHeight: '15px',
        borderColor: '#00CC88'
      },
      longValue: null,
      tStyle: {
        inner:
          'bg-white w-[500px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] px-6 sm:py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'sm:text-center sm:mt-[50px] mt-[30px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        confirm:
          'w-full sm:h-[60px] flex sm:mt-[20px] my-6 sm:mb-[10px] max-sm:text-[15px] fold:text-[15px]',
        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setAlertData, setAlertOpen } = useModalControl();
    const useLeverage = create(leverage);
    const { longLeverage, setLongLev } = useLeverage();
    const useDarkMode = create(darkMode);
    const { isDarkMode } = useDarkMode();
    const useAggSocketData = create(aggSocketData);
    const {
      btcAggData,
      ethAggData,
      xrpAggData,
      maticAggData,
      dogeAggData,
      arbAggData,
      seiAggData,
      solAggData,
      stxAggData,
      avaxAggData,
      gasAggData,
      axsAggData
    } = useAggSocketData();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useOrderConfirm = create(orderConfirm);
    const { setApiCall } = useOrderConfirm();

    return {
      setModalControl,
      longLeverage,
      setLongLev,
      isDarkMode,
      btcAggData,
      ethAggData,
      xrpAggData,
      maticAggData,
      dogeAggData,
      apiLangCode,
      setApiCall,
      setAlertData,
      setAlertOpen,
      arbAggData,
      seiAggData,
      solAggData,
      stxAggData,
      avaxAggData,
      gasAggData,
      axsAggData
    };
  },
  methods: {
    // 레버리지별 최대 배수
    async getLeverageMax(){
      let data = JSON.stringify({
        data: AesEncrypt({
          symbol: this.selectSymbol
        })
      })
      
      let result = await Request(
        '/api/exs/main/v1/request_leverage_info',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      )
      if(!result) return
      
      let {status , message} = result.API_CODE
      if(status === '0000'){
        this.leverageList = result.leverage_list
      } else {
        this.setAlertData({
          title: '',
          desc: message
        });
        this.setAlertOpen();
      }
    },
    // 레버리지 변경 api
    async changeLeverage() {
      await this.setPrice();
      let data = JSON.stringify({
        data: AesEncrypt({
          symbol: this.selectSymbol,
          destLs: 'long',
          price: this.recentPrice.p,
          leverage: this.longValue
        })
      });
      let result = await Request(
        '/api/exs/trade/v1/request_set_leverage',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );
      let { status, message } = result.API_CODE;

      if (status == '0000') {
        this.setApiCall();
      } else if (status == '0020') {
        this.setLongLev(this.longValue);
      } else {
        this.setAlertData({
          title: '',
          desc: message
        });
        this.setAlertOpen();
      }
    },
    setPrice() {
      if (this.selectSymbol == 'btc') {
        this.recentPrice = this.btcAggData;
      } else if (this.selectSymbol == 'eth') {
        this.recentPrice = this.ethAggData;
      } else if (this.selectSymbol == 'xrp') {
        this.recentPrice = this.xrpAggData;
      } else if (this.selectSymbol == 'matic') {
        this.recentPrice = this.maticAggData;
      } else if (this.selectSymbol == 'doge') {
        this.recentPrice = this.dogeAggData;
      } else if (this.selectSymbol == 'arb') {
        this.recentPrice = this.arbAggData;
      } else if (this.selectSymbol == 'sei') {
        this.recentPrice = this.seiAggData;
      } else if (this.selectSymbol == 'sol') {
        this.recentPrice = this.solAggData;
      } else if (this.selectSymbol == 'stx') {
        this.recentPrice = this.stxAggData;
      } else if (this.selectSymbol == 'avax') {
        this.recentPrice = this.avaxAggData;
      } else if (this.selectSymbol == 'gas') {
        this.recentPrice = this.gasAggData;
      } else if (this.selectSymbol == 'axs') {
        this.recentPrice = this.axsAggData;
      }
    },
    leverageSett() {
      sessionStorage.setItem('fubit_long', this.longValue);
      this.changeLeverage();
      this.setModalControl();
    },
    plusCount() {
      if (this.longValue !== this.maxValue) {
        this.longValue++;
      }
    },
    minusCount() {
      if (this.longValue !== 1) {
        this.longValue--;
      }
    }
  },
  mounted() {
    this.longValue =
      this.selectSymbol === 'sei' && parseInt(this.longLeverage) > 20
        ? 20
        : parseInt(this.longLeverage);
    this.isDark = this.isDarkMode;
    this.getLeverageMax()
  },
  components: {
    VueSlider
  },
  created() {
    const session = sessionStorage.getItem('fubit_symbol');
    if (session !== null) {
      this.selectSymbol = this.coinList[session - 1];
    }
  },
  computed: {
    maxValue() {
      if (this.selectSymbol === 'btc') {
        return 125;
      } else if (
        this.selectSymbol === 'eth' ||
        this.selectSymbol === 'sol') {
        return 100;
      } else if (
        this.selectSymbol === 'xrp' ||
        this.selectSymbol === 'avax'
      ) {
        return 75;
      } else if (
        this.selectSymbol === 'doge' ||
        this.selectSymbol === 'matic' ||
        this.selectSymbol === 'arb' ||
        this.selectSymbol === 'sei' ||
        this.selectSymbol === 'gas' ||
        this.selectSymbol === 'axs'
      ) {
        return 50;
      } else if (
        this.selectSymbol === 'stx'
      ) {
        return 25;
      }
    },
    marks() {
      if (this.selectSymbol === 'btc') {
        const a = {
          1: '',
          25: '',
          50: '',
          75: '',
          100: '',
          125: ''
        };
        return a;
      } else if (
        this.selectSymbol === 'eth' ||
        this.selectSymbol === 'sol') {
        const a = {
          1: '',
          25: '',
          50: '',
          75: '',
          100: ''
        };
        return a;
      } else if (
        this.selectSymbol === 'xrp' ||
        this.selectSymbol === 'avax') {
        const a = {
          1: '',
          15: '',
          30: '',
          45: '',
          60: '',
          75: ''
        };
        return a;
      } else if (
        this.selectSymbol === 'doge' ||
        this.selectSymbol === 'matic' ||
        this.selectSymbol === 'arb' ||
        this.selectSymbol === 'sei' ||
        this.selectSymbol === 'gas' ||
        this.selectSymbol === 'axs'
      ) {
        const a = {
          1: '',
          10: '',
          20: '',
          30: '',
          40: '',
          50: ''
        };
        return a;
      } else if (this.selectSymbol === 'stx') {
        const a = {
          1: '',
          5: '',
          10: '',
          15: '',
          20: '',
          25: ''
        };
        return a;
      }
    },
    maxPositionPrice() {
      // const a = this.selectSymbol;
      // const b = this.longValue;
      // if (a == 'btc') {
      //   if (b == 1) return '50,000,000';
      //   if (b == 2) return '30,000,000';
      //   if (b == 3) return '20,000,000';
      //   if (b == 4) return '10,000,000';
      //   if (b == 5) return '5,000,000';
      //   if (5 < b && b < 11) return '2,000,000';
      //   if (10 < b && b < 21) return '1,000,000';
      //   if (20 < b && b < 51) return '100,000';
      //   if (50 < b && b < 101) return '25,000';
      //   if (100 < b) return '5,000';
      // } else if (a == 'eth') {
      //   if (b == 1) return '30,000,000';
      //   if (b == 2) return '15,000,000';
      //   if (b == 3) return '8,000,000';
      //   if (b == 4) return '4,000,000';
      //   if (b == 5) return '2,000,000';
      //   if (5 < b && b < 11) return '1,000,000';
      //   if (10 < b && b < 21) return '500,000';
      //   if (20 < b && b < 51) return '100,000';
      //   if (50 < b && b < 76) return '25,000';
      //   if (75 < b) return '10,000';
      // } else if (a == 'matic') {
      //   if (b == 1) return '1,000,000';
      //   if (b == 2) return '300,000';
      //   if (b == 3 || b == 4) return '75,000';
      //   if (b == 5) return '50,000';
      //   if (5 < b && b < 11) return '25,000';
      //   if (10 < b && b < 21) return '15,000';
      //   if (20 < b && b < 26) return '5,000';
      //   if (25 < b && b < 41) return '2,500';
      //   if (40 < b) return '500';
      // } else if (a == 'xrp') {
      //   if (b == 1) return '5,000,000';
      //   if (b == 2) return '2,000,000';
      //   if (b == 3) return '1,000,000';
      //   if (b == 4) return '500,000';
      //   if (b == 5) return '200,000';
      //   if (5 < b && b < 11) return '100,000';
      //   if (10 < b && b < 26) return '25,000';
      //   if (25 < b && b < 41) return '5,000';
      //   if (40 < b && b < 51) return '1,000';
      //   if (50 < b) return '500';
      // } else if (a == 'doge') {
      //   if (b == 1) return '2,000,000';
      //   if (b == 2) return '1,000,000';
      //   if (b == 3 || b == 4) return '500,000';
      //   if (b == 5) return '200,000';
      //   if (5 < b && b < 11) return '100,000';
      //   if (10 < b && b < 21) return '25,000';
      //   if (20 < b && b < 26) return '5,000';
      //   if (25 < b && b < 41) return '2,500';
      //   if (40 < b) return '500';
      // } else if (a == 'sei') {
      //   if (b == 1) return '500,000';
      //   if (b == 2) return '300,000';
      //   if (b == 3 || b == 4) return '120,000';
      //   if (b == 5) return '70,000';
      //   if (5 < b && b < 11) return '30,000';
      //   if (10 < b && b < 16) return '2,500';
      //   if (15 < b ) return '500';
      // } else if (a == 'arb') {
      //   if (b == 1) return '20,000,000';
      //   if (b == 2) return '12,000,000';
      //   if (b == 3 || b == 4) return '5,000,000';
      //   if (b == 5) return '3,000,000';
      //   if (b == 6 || b == 7) return '1,200,000';
      //   if (7 < b && b < 11) return '120,000';
      //   if (10 < b && b < 21) return '60,000';
      //   if (20 < b && b < 26) return '5,000';
      //   if (25 < b) return '500';
      // }
      return this.leverageList[this.longValue - 1]?.maxMargin.toLocaleString()
    }
  }
};
</script>
<style lang=""></style>
