import { createStore } from 'zustand/vanilla';

//현재 페이지 감시
export let currPage = createStore((set) => ({
  thisPage: 6,
  setCurrPage(data) {
    set(() => ({
      thisPage: data
    }));
  }
}));

//다크모드 상태
export let darkMode = createStore((set) => ({
  isDarkMode: false,
  setDarkMode() {
    set(() => ({ isDarkMode: true }));
    localStorage.setItem('fubit_theme', 'dark');
  },
  resetDarkMode() {
    set(() => ({ isDarkMode: false }));
    localStorage.setItem('fubit_theme', 'light');
  }
}));

//전화번호 국제 번호 모달
export let nationNumModal = createStore((set) => ({
  nationNumState: false,
  setNationNumModalOpen() {
    set(() => ({ nationNumState: true }));
  },
  setNationNumModalClose() {
    set(() => ({ nationNumState: false }));
  },
  selectNationCode: '82',
  setNationCode(code) {
    set(() => ({
      selectNationCode: code
    }));
  }
}));

//메일 인증번호 인풋창 구현
export let mailCertNum = createStore((set) => ({
  state: [],
  onChange(e) {
    const value = e.target.value;
    const newArr = Array.from(value);
    set(() => ({ state: newArr }));
  }
}));

// 로그인 상태 체크
export let isLogin = createStore((set) => ({
  loginState: false,
  loginModal: false,
  setLogin() {
    set(() => ({
      loginState: true
    }));
  },
  setLogout() {
    set(() => ({
      loginState: false
    }));
    localStorage.removeItem('fubit_uuid');
  },
  setLoginModal() {
    set((state) => ({
      loginModal: !state.loginModal
    }));
  }
}));

//탑바 국가 설정 모달
export let nation = createStore((set) => ({
  languageState: 'en',
  nationModalState: false,
  setNationSelect(res) {
    set(() => ({
      languageState: res
    }));
  }
}));

//메인 진입여부 확인 (탑바 공지사항 가리기)
export let topNotice = createStore((set) => ({
  isMain: true,
  setIsMainTrue() {
    set(() => ({
      isMain: true
    }));
  },
  setIsMainFalse() {
    set(() => ({
      isMain: false
    }));
  }
}));

//레이아웃 설정 모달
export let layoutModal = createStore((set) => ({
  layoutModalState: false,
  setLayoutModalOpen() {
    set(() => ({
      layoutModalState: true
    }));
  },
  setLayoutModalClose() {
    set(() => ({
      layoutModalState: false
    }));
  }
}));

//사운드 설정 모달
export let soundModal = createStore((set) => ({
  soundModalState: false,
  setSoundModalOpen() {
    set(() => ({
      soundModalState: true
    }));
  },
  setSoundModalClose() {
    set(() => ({
      soundModalState: false
    }));
  }
}));

//내 지갑 자산 숨기기 버튼
// export let walletHide = createStore(set => ({
//   isWalletHide: false,
//   setHideState() {
//     set(state => ({
//       isWalletHide: !state.isWalletHide
//     },
//     localStorage.setItem('fubit_asset_hide', state.isWalletHide)
//     ));
//   },
// }));

//모달 on/off 모듈화
export const modalControl = createStore((set) => ({
  isModalOpen: false,
  whatModal: '',
  alertOpen: false,
  alertData: {
    title: '',
    desc: '',
    type: ''
  },
  setModalName(modalName) {
    set(() => ({
      whatModal: modalName
    }));
  },
  setModalControl() {
    set((state) => ({
      isModalOpen: !state.isModalOpen
    }));
  },
  setAlertData(data) {
    set(() => ({
      alertData: data
    }));
  },
  setAlertOpen() {
    set((state) => ({
      alertOpen: !state.alertOpen
    }));
  }
}));

//자금이체 모달 데이터
export const moneyTransfer = createStore((set) => ({
  isFuture: false,
  setFuture() {
    set(() => ({
      isFuture: true
    }));
  },
  setActual() {
    set(() => ({
      isFuture: false
    }));
  }
}));

// 팝업 관리
export const popupState = createStore((set) => ({
  isPop: false,
  setPopOpen() {
    set(() => ({
      isPop: true
    }));
  },
  setPopClose() {
    set(() => ({
      isPop: false
    }));
  }
}));

//api reloading
export const refresh = createStore((set) => ({
  walletLoad: false,
  loadApi: '',
  setRefresh(data) {
    set(() => ({
      loadApi: data
    }));
  },
  setWalletReload(){
    set((state)=>({
      walletLoad: !state.walletLoad
    }))
  }
}));

// 다국어 api호출
export const apiLanguage = createStore(() => ({
  apiLangCode: {
    ko: 'kr',
    en: 'en',
    es: 'es',
    ru: 'ru',
    zh: 'ch',
    ja: 'jp',
    vi: 'vn'
  }
}));

// 고객센터 모바일 상세화면 seq저장
export const noticeStore = createStore((set) => ({
  noticeSeq: null,
  setNoticeSeq(data) {
    set(() => ({
      noticeSeq: data
    }));
  }
}));

// 유저 세팅정보 저장
export const userSetting = createStore((set) => ({
  setUpdate: false,
  optionData: {},
  setOption(data) {
    set(() => ({
      optionData: data
    }));
  },
  updating() {
    set((state) => ({
      setUpdate: !state.setUpdate
    }));
  }
}));

// 프로모션 센터 친구초대 코드
export const inviteCodeStore = createStore((set) => ({
  codeState: {},
  setCodeState(data) {
    set(() => ({
      codeState: data
    }));
  }
}));

// 심볼 자리수 정리
export const symbolNumLength = createStore(() => ({
  numLength: {
    btc: '',
    xrp: '',
    eth: '',
    matic: '',
    doge: '',
    usdt: '',
    arb: '',
    sei: '',
    sol: '',
    stx: '',
    avax: '',
    gas: '',
    axs: ''
  }
}));


// api loading 화면 제어
export const serverLoad = createStore((set)=>({
  serverLoading: true,
  setServerLoading(){
    set(()=>({
      serverLoading: false
    }))
  },
  setServerLoadingEnd(){
    set(()=>({
      serverLoading: true
    }))
  }
}))

//system update
export const system = createStore((set)=>({
  serviceCheck: false,
  setServiceCheck(){
    set(()=>({
      serviceCheck: false
    }))
  }
}))

export const checkMobile = createStore((set)=>({
  etcMobile: false,
  setIsMobile(){
    set(()=>({
      etcMobile: true
    }))
  },
  removeMobile(){
    set(()=>({
      etcMobile: false
    }))
  }
}))